"use client";

import { useAction } from "@/lib/trpc/client";
import {
  forgotPasswordAuthAction,
  loginAuthAction,
} from "@/server/routers/authActions";
import { Alert, Button, Card, NavLink, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { AlertOctagonIcon, ShieldQuestionIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import { Form, useForm } from "react-hook-form";
import { PasswordInput, TextInput } from "react-hook-form-mantine";
import isEmail from "validator/lib/isEmail";

export default function LoginForm() {
  const router = useRouter();
  const login = useAction(loginAuthAction, {
    onSuccess: () => router.push("/dashboard"),
  });
  const forgotPassword = useAction(forgotPasswordAuthAction, {
    onSuccess: (result) => {
      notifications.show({
        message: "Please check your email for a password reset link!",
        color: "green",
      });
    },
    onError: (error) => {
      notifications.show({
        message: `Password reset email could not be sent: ${error.message}`,
        color: "red",
      });
    },
  });

  const form = useForm({ defaultValues: { email: "", password: "" } });

  return (
    <Card p="xl">
      <Text className="text-center text-2xl font-bold">Welcome Back</Text>
      <Text className="mb-8 text-center text-gray-4">
        Log in to continue using RealCount
      </Text>
      <Form
        className="flex flex-col space-y-4"
        control={form.control}
        onSubmit={({ data }) => {
          login.mutate(data);
        }}
      >
        <TextInput
          control={form.control}
          name="email"
          label="Email"
          type="email"
          rules={{
            required: true,
            validate: (email) => isEmail(email) || "Invalid email address",
          }}
        />

        <PasswordInput
          control={form.control}
          name="password"
          label="Password"
          type="password"
          rules={{
            required: true,
            validate: (password) =>
              password.length >= 8 || "Password must be at least 8 characters",
          }}
        />

        <NavLink
          label="Forgot password?"
          leftSection={<ShieldQuestionIcon size={18} />}
          variant="subtle"
          active
          onClick={async () => {
            let email = form.getValues("email");
            if (!isEmail(email)) {
              try {
                email = await new Promise((resolve, reject) => {
                  modals.open({
                    title: "Forgot password",
                    classNames: { title: "text-xl font-bold" },
                    padding: "lg",
                    size: "xs",
                    withCloseButton: true,
                    onClose: () => reject(),
                    children: (
                      <>
                        <TextInput
                          control={form.control}
                          name="email"
                          label="Email"
                          type="email"
                        />
                        <Text className="my-2 text-sm text-gray-5">
                          We will send you a password reset link to this email
                          address.
                        </Text>
                        <Button
                          mt="lg"
                          fullWidth
                          onClick={() => {
                            const input = form.getValues("email");
                            if (!isEmail(input)) {
                              return notifications.show({
                                message: "Invalid email address",
                                color: "red",
                              });
                            }
                            modals.closeAll();
                            resolve(input);
                          }}
                        >
                          Submit
                        </Button>
                      </>
                    ),
                  });
                });
              } catch {
                return;
              }
            }
            forgotPassword.mutate({ email });
          }}
        />

        {login.status == "error" && (
          <Alert
            className="!mt-6"
            color="red"
            title={login.error.message}
            icon={<AlertOctagonIcon />}
          />
        )}

        <Button
          className="!mt-6"
          loading={login.status == "loading"}
          type="submit"
          color="blue"
          fullWidth
        >
          Login
        </Button>
      </Form>
    </Card>
  );
}
