"use client";
import { trpc } from "@/lib/trpc/query/client";
import { cn, type ObjectValues } from "@/lib/utils";
import {
  Button,
  Card,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";

const SIGNUP_ROLES = {
  AGENCY: "Agency",
  ARTIST: "Artist/Artist Management",
  PROMOTER: "Promoter",
  VENUE: "Venue",
  OTHER: "Other",
} as const;

export type SignupRoleType = ObjectValues<typeof SIGNUP_ROLES>;

export function WaitlistDialog({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState<SignupRoleType | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [mailingList, setMailingList] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validEmail = email === "" || isEmail(email);

  const addToWaitlist = trpc.landing.submitWaitlist.useMutation({
    onSuccess: () => {
      notifications.show({ message: "Sign up submitted!" });
      setSubmitted(true);
      setOpen(false);
    },
    onError: () => {
      notifications.show({
        message:
          "An error occurred while attempting to submit to the waitlist.",
      });
    },
  });

  return (
    <Card className="w-[100vw] max-w-xl" py="xl">
      <Stack>
        <Text className="text-primary-dark text-center text-2xl font-bold">
          Tell us who you are!
        </Text>
        {/* Form content */}
        <Stack>
          <TextInput
            required
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            required
            className={cn(!validEmail && "focus-visible:ring-red-5")}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!validEmail}
          />
          <TextInput
            required
            label="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <Select
            required
            label="What best describes your role?"
            onChange={(value) => {
              setRole(value as SignupRoleType);
            }}
            data={Object.entries(SIGNUP_ROLES).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          />

          <Switch
            label="Add me to the mailing list"
            checked={mailingList}
            onChange={(e) => setMailingList(e.target.checked)}
          />

          <Stack align="center" pt="xl">
            {errorMsg && (
              <Text className="text-center text-red-5">{errorMsg}</Text>
            )}
            <Button
              className="w-32 self-center"
              onClick={() => {
                setErrorMsg("");
                if (!validEmail || !role || !company || !name)
                  setErrorMsg("Please fill out all required fields.");
                else
                  addToWaitlist.mutate({
                    name,
                    email,
                    company,
                    role,
                    mailingList,
                    tos: true,
                  });
              }}
              disabled={submitted}
              loading={addToWaitlist.isPending}
            >
              {submitted ? "Submitted" : "Sign Up"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
