"use client";

import { getBaseUrl } from "@/lib/urls";
import type { AppRouter } from "@/server/appRouter";
import { loggerLink } from "@trpc/client";
import {
  experimental_createActionHook,
  experimental_createTRPCNextAppDirClient,
  experimental_serverActionLink,
} from "@trpc/next/app-dir/client";
import { experimental_nextHttpLink } from "@trpc/next/app-dir/links/nextHttp";
// import { createSupabaseClient } from "createSupabaseClient";
import superjson from "superjson";

export const api = experimental_createTRPCNextAppDirClient<AppRouter>({
  config: () => {
    return {
      links: [
        loggerLink({
          colorMode: "ansi",
          enabled: (op) => {
            return (
              process.env.NODE_ENV == "development" ||
              (op.direction == "down" && op.result instanceof Error)
            );
          },
        }),
        experimental_nextHttpLink({
          // revalidate: 0,
          transformer: superjson,
          batch: true,
          // methodOverride: "POST",
          url: `${getBaseUrl()}/api/trpc`,
          headers: async (opts) => {
            return {
              "x-trpc-source": "client",
            };
          },
        }),
      ],
    };
  },
});

export const useAction = experimental_createActionHook<AppRouter>({
  links: [
    loggerLink({
      enabled: (op) => {
        return (
          process.env.NODE_ENV == "development" ||
          (op.direction == "down" && op.result instanceof Error)
        );
      },
    }),
    experimental_serverActionLink({
      transformer: superjson,
    }),
  ],
});
